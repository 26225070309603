<template>
  <div class="comment-card">
    <div class="img" :style="img_style"></div>
    <div class="comment-card-inner">
      <h3 class="title" v-html="title"></h3>
      <p class="name" v-html="name"></p>
      <div class="tag-wrap">
        <span class="tag" v-for="tag in tags">{{ tag }}</span>
      </div>
      <p class="comment" v-if="comment" v-html="comment"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
    },
    imgs: {
      type: Object,
    },
    tags: {
      type: Array,
    },
    comment: {
      type: String,
    }
  },
  data() {
    return {
      img_style: {
        'background': `url("./comment_images/${this.imgSrc(this.imgs)}")`,
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
      }
    }
  },
  methods: {
    imgSrc: (imgs) => {
      const pixel = window.devicePixelRatio;
      if (pixel >= 2 && imgs['2x']) {
        return imgs['2x'];
      }
      return imgs['1x'];
    }
  }
}
</script>
