<template>
  <div :class="['faq', {open: isOpen}]" @click="toggle">
    <div class="question">
      <span class="icon-q">Q</span>
      <p>{{ question }}</p>
    </div>
    <SlideUpDown :active="isOpen" :duration="150">
      <div class="answer">
        <span class="icon-a">A</span>
        <pre>{{ answer }}</pre>
      </div>
    </SlideUpDown>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'Faq',
  components: {
    SlideUpDown
  },
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    question: {
      type: String,
      required: false
    },
    answer: {
      type: String,
      required: false
    }
  },
  methods: {
    toggle: function() {
      this.isOpen = !this.isOpen
    }
  }
};
</script>
