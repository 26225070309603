<template>
  <a :class="['btn', classes || '']" :href="href" :target="(blankIcon || isBlank)? '_blank':false">

    <span v-if="mailIcon" class="btn-icon-l icon">
      <img srcset="../../../image/pc/icon-mail.png 1x, ../../../image/pc/icon-mail@2x.png 2x" class="btn-icon-mail">
    </span>
    <span v-if="dlIcon" class="btn-icon-l icon">
      <img srcset="../../../image/pc/icon-download.png 1x, ../../../image/pc/icon-download@2x.png 2x" class="btn-icon-mail">
    </span>

    {{ text }}

    <span v-if="blankIcon" class="btn-icon-r icon">
      <img srcset="../../../image/pc/icon-link.png 1x, ../../../image/pc/icon-link@2x.png 2x" class="btn-icon-blank">
    </span>

  </a>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    text: {
      type: String,
      required: false
    },
    classes: {
      required: false
    },
    href: {
      type: String,
      required: false
    },
    mailIcon: {
      type: Boolean,
      default: false,
    },
    dlIcon: {
      type: Boolean,
      default: false,
    },
    blankIcon: {
      type: Boolean,
      default: false,
    },
    isBlank: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
