<template>
  <div>
    <div class="card-row" v-for="datas in CardData">
      <template  v-for="data in datas">
        <Card v-bind="data"></Card>
      </template>
    </div>
  </div>
</template>

<script>
import Card from '../parts/Card'
import CardJson from '../../../data/card.json'

export default {
  name: 'CardList',
  components: {
    Card,
  },
  props: {
    dataKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      CardData: CardJson[this.dataKey]
    }
  }
}
</script>
