import Vue from 'vue';
import App from './App';

import vueSmoothScroll from 'vue-smooth-scroll'
Vue.use(vueSmoothScroll)

import picturefill from 'picturefill';
picturefill();

new Vue({
  el: '#app',
  render: h => h(App)
})
