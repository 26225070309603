<template>
  <header :class="{'scrolled': isScrolled}">
      <div class="header-inner">
        <div>
          <div class="logo">
            <a href="/">
              <img srcset="../../../image/pc/logo-startdelivery.png 1x, ../../../image/pc/logo-startdelivery@2x.png 2x" alt="スタートデリバリー">
            </a>
          </div>
          <nav class="pc-nav">
            <ul>
              <li v-for="menu in menuData"><a :href="`#${menu.key}`" :class="`gtm-click-nav-pc-${menu.key}`" v-smooth-scroll="{ duration: 1000, offset: -82 }">{{ menu.name }}</a></li>
            </ul>
          </nav>
        </div>
        <div>
          <Btn
            text="お問い合わせ"
            classes="btn-black gtm-click-inquiry-header lg-hide"
            :isBlank="true"
            :mailIcon="true"
            :href="links.inquiry_link"
            ></Btn>
          <Btn
            text="資料ダウンロード"
            classes="btn-red gtm-click-download-header"
            :isBlank="true"
            :dlIcon="true"
            :href="links.download_inquiry_link"
            ></Btn>
          <span :class="['sp-menu-btn', {open: isOpen}]" @click="spNavToggle"></span>
        </div>
      </div>
      <SlideUpDown :active="isOpen" :duration="150" class="sp-nav">
      <nav @click="spNavToggle">
        <ul>
          <li v-for="menu in menuData"><a :href="`#${menu.key}`" :class="`gtm-click-nav-sp-${menu.key}`" v-smooth-scroll="{ duration: 1000, offset: -40 }">{{ menu.name }}</a></li>
          <li><a :href="links.inquiry_link" class="gtm-click-nav-sp-inquiry" target="_blank">
              <img srcset="../../../image/sp/icon-mail-small.png 1x, ../../../image/sp/icon-mail-small@2x.png 2x" class="btn-icon-mail">
              お問い合わせ</a></li>
        </ul>
        <div class="close-btn-wrap">
          <div class="close-btn">閉じる</div>
        </div>
      </nav>
      </SlideUpDown>
    </header>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'
import Btn from '../parts/Btn'
import LinksJson from '../../../data/links.json'
import MenuJson from '../../../data/menu.json'

export default {
  name: 'SectionBox',
  components: {
    Btn,
    SlideUpDown
  },
  data() {
    return {
      isScrolled: ((window.scrollY || window.pageYOffset) > 100),
      isOpen: false,
      headerHeight: 0,
      links: LinksJson,
      menuData: MenuJson
    }
  },
  props: {
    title: {
      type: String,
      required: false
    }
  },
  mounted() {
    this.headerHeight = this.$el.getElementsByClassName('header-inner')[0].offsetHeight;
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    spNavToggle: function() {
      this.isOpen = !this.isOpen;
    },
    handleScroll: function(evt, el) {
      const y = window.scrollY || window.pageYOffset;
      if (y > 500) {
        return this.isScrolled = true;
      }
      this.isScrolled = false;
    }
  }
};
</script>
