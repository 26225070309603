<template>
  <div class="card">
    <div class="card-inner">
      <span class="title" v-if="title">{{ title }}</span>
      <div class="img">
        <img :srcset="img_src" :alt="title">
      </div>
      <p class="body" v-if="body" v-html="body"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      required: false
    },
    imgs: {
      type: String,
      required: false
    },
    body: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      img_src: Object.keys(this.imgs).map(k => {
        const v = this.imgs[k];
        return `./card_images/${v} ${k}`
      }).join(",")
    }
  }
}
</script>
