<template>
  <div class="introduction-card">
    <img :srcset="srcSetCreate(headerImgs)" class="header-img">
    <div class="introduction-card-inner">
      <p class="comment" v-html="comment"></p>

      <div class="flex center flex-wrap img">
        <span>
          <img :srcset="srcSetCreate(bodyImgs)" :alt="name">
        </span>
      </div>

      <div class="point">
        <p class="title">{{ name }}のここがすごい！</p>
        <p class="body" v-html="point"></p>
      </div>

      <div class="flex btn-group">
        <Btn text="詳しくはこちら" :classes="['btn-black-border', 'size-l', `gtm-click-market-${nameSlug}-detail`]" :blankIcon="true" :href="serviceUrl"></Btn>
        <Btn :text="`${name}サイトへ`" :classes="['btn-black-border', 'size-l', `gtm-click-market-${nameSlug}-site`]" :blankIcon="true" :href="siteUrl"></Btn>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from './Btn';

export default {
  name: 'IntroductionCard',
  components: {
    Btn
  },
  props: {
    name: {
      type: String
    },
    nameSlug: {
      type: String
    },
    headerImgs: {
      type: Object
    },
    comment: {
      type: String
    },
    bodyImgs: {
      type: Object
    },
    point: {
      type: String
    },
    serviceUrl: {
      type: String
    },
    siteUrl: {
      type: String
    }
  },
  methods: {
    srcSetCreate: (imgs) => {
      return Object.keys(imgs).map(k => {
        const v = imgs[k];
        return `./introduction_images/${v} ${k}`
      }).join(",")
    }
  }
};
</script>
