<template>
  <div class="area-table-wrap">
    <table class="area-table" v-for="data in datas">
      <tr v-for="(areas, key) in data">
        <th>{{ key }}</th>
        <td>
          <span class="area-name" v-for="area in areas">{{ area }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import areaJson from '../../../data/area.json';
export default {
  name: 'AreaTable',
  data() {
    return {
      datas: areaJson
    }
  }
}
</script>
