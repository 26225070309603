<template>
  <section :id="id" :class="classes">
    <div class="section-inner">
      <h2 v-if="title">{{ title }}</h2>
      <div class="section-body">
        <slot name="body"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionBox',
  props: {
    title: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    classes: {
      type: String,
      required: false
    }
  }
};
</script>
