<template>
  <div class="container">
    <Header></Header>
    <Main></Main>
    <SectionBox classes="catch-section">
      <slot slot="body">
        <p>
          「レストランの“ごちそう”をいつでもどこでも食べられる」<br />
          という世界を飲食店のみなさまと一緒に創ってまいります。
        </p>
      </slot>
    </SectionBox>
    <SectionBox id="about" classes="about-section">
      <div slot="body">
        <div class="flex center flex-wrap">
          <span>
            <img
              srcset="
                ../image/pc/logo-startdelivery.png    1x,
                ../image/pc/logo-startdelivery@2x.png 2x
              "
              alt="スタートデリバリー"
              class="logo"
            />
          </span>
        </div>
        <p>
          スタートデリバリーはスターフェスティバルが運営する中食・デリバリー参入支援サービスです。<br />
          昨今、食事を楽しむ選択肢として、「お客さまが、飲食店に足を運ぶ」外食のみならず、「飲食店の外にいるお客さまの元へ料理を届ける」というデリバリーサービス市場が急速に拡大しています。<br />
          「スタートデリバリー」は、全国に80万店舗ある飲食店の方々が中食に参入する際のハードルを取り除き、少額の費用投資のみで、簡単・スムーズにデリバリー事業をはじめられるようサポートさせていただくサービスです。<br />
          <br />
          <span class="bold-text"
            >デリバリーに参入するうえで必要なソリューション・販路を一気通貫ですべて提供いたします。</span
          >
        </p>
      </div>
    </SectionBox>
    <SectionBox title="スタートデリバリーの特徴" classes="feature-section">
      <slot slot="body">
        <ul>
          <li>
            少額の費用投資のみで簡単・スムーズにデリバリー事業が始められる
          </li>
          <li>飲食店・レストランが担うのは 製造(調理）の部分のみ</li>
          <li>
            「商品開発」「販路提供」「販売促進」「注文受付」「決済」「配達」のすべてをスターフェスティバルが
            一気通貫でトータルサポート
          </li>
        </ul>

        <div class="flex center flex-wrap">
          <span class="flow-image"></span>
        </div>
      </slot>
    </SectionBox>
    <SectionBox title="サービス概要" id="service" classes="service-section">
      <slot slot="body">
        <p class="sub-title">
          以下のサービスを一気通貫でサポート・提供いたします。
        </p>
        <CardList dataKey="service"></CardList>
      </slot>
    </SectionBox>
    <SectionBox
      title="日本最大級のフードデリバリー総合モールの販路を提供"
      classes="market-section"
    >
      <slot slot="body">
        <p class="sub-title">
          「ごちクル」「シャショクル」をはじめ、BtoBに特化したフードデリバリーサービスを販路として活用することで、効率的かつ計画的に安定した製造が可能です。
        </p>
        <div class="flex between mb-80 introductions">
          <template v-for="introduction in introductions">
            <IntroductionCard v-bind="introduction"></IntroductionCard>
          </template>
        </div>
        <div class="mb-80 flex gckr-now">
          <img
            class="service_start"
            srcset="
              ../image/pc/txt-now-catch.png    1x,
              ../image/pc/txt-now-catch@2x.png 2x
            "
            alt="2020年サービススタート！"
          />
          <div class="gckr-now-image"></div>
          <div class="gckr-now-content">
            <div class="gckr-now-content-head">
              <h4>
                <img
                  srcset="
                    ../image/pc/logo-gochikuru-now.png    1x,
                    ../image/pc/logo-gochikuru-now@2x.png 2x
                  "
                  alt="ごちクルNow"
                />
              </h4>
              <p>(ワーカー向けサービス)</p>
              <p>gochikuru-now.com/lp/</p>
            </div>
            <p class="gckr-now-content-description">
              午前10時までのご注文で、当日のランチタイムに社内までお届けするサービス。発注は2日以上前で、売れ残り分は買取（一部条件有）の為、計画的な製造が可能です。
            </p>
            <btn
              text="ごちクルNowサイトへ"
              classes="btn-black-border"
              :blankIcon="true"
              href="https://gochikuru-now.com/lp/"
            ></btn>
          </div>
        </div>
        <h3>多数のメディアで紹介実績あり！</h3>

        <div class="media-images">
          <template v-for="media in medias">
            <span class="media-wrap" :style="`order: ${media.order}`">
              <img :src="`./media_logo/${media.image}`" :alt="media.alt" />
            </span>
          </template>
          <span class="media-image-space"></span>
        </div>
      </slot>
    </SectionBox>
    <SectionBox title="安心・安全への取り組み" classes="approach-section">
      <slot slot="body">
        <p class="sub-title">
          スタートデリバリーでは、サービスの品質向上のため、徹底したガイドラインを作成し、管理しています。
        </p>
        <CardList dataKey="approach"></CardList>
      </slot>
    </SectionBox>
    <SectionBox classes="bento-section">
      <slot slot="body">
        <h3>
          製造いただくお弁当は、ジャンル、価格帯、のしのデザインを含めて<br />開発担当との打ち合わせを経て決定します
        </h3>
        <div class="flex between flex-wrap bent-images">
          <img
            srcset="
              ../image/pc/img-bento-hatake.png    1x,
              ../image/pc/img-bento-hatake@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-akanetei.png    1x,
              ../image/pc/img-bento-akanetei@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-rikyu.png    1x,
              ../image/pc/img-bento-rikyu@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-yonehachi.png    1x,
              ../image/pc/img-bento-yonehachi@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-hisio.png    1x,
              ../image/pc/img-bento-hisio@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-afusoya.png    1x,
              ../image/pc/img-bento-afusoya@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-tokyoegao.png    1x,
              ../image/pc/img-bento-tokyoegao@2x.png 2x
            "
          />
          <img
            srcset="
              ../image/pc/img-bento-dersou.png    1x,
              ../image/pc/img-bento-dersou@2x.png 2x
            "
          />
        </div>
      </slot>
    </SectionBox>
    <SectionBox title="導入飲食店様の声" id="comment" classes="comment_section">
      <slot slot="body">
        <template v-for="comment in comments">
          <CommentCard v-bind="comment"></CommentCard>
        </template>
      </slot>
    </SectionBox>
    <SectionBox title="サービス対応エリア" id="area" classes="area-section">
      <slot slot="body">
        <p class="sub-title">
          東京エリアを中心に、札幌・仙台・名古屋・大阪・広島・福岡など広い範囲のエリアをカバーしています。（※一部地域除く）
        </p>

        <AreaTable></AreaTable>
      </slot>
    </SectionBox>
    <SectionBox title="よくあるご質問" classes="faq-section">
      <slot slot="body">
        <div class="flex center flex-wrap">
          <template v-for="faq in faqs">
            <Faq :question="faq.question" :answer="faq.answer"></Faq>
          </template>
        </div>
      </slot>
    </SectionBox>
    <SectionBox classes="inquiry-section">
      <slot slot="body">
        <h3>ご相談・お問い合わせはこちら</h3>
        <div class="flex center btn-wrap">
          <Btn
            text="お問い合わせ"
            classes="btn-black size-l gtm-click-inquiry-inquiry-section"
            :mailIcon="true"
            :isBlank="true"
            :href="links.inquiry_link"
          ></Btn>
          <Btn
            text="資料ダウンロード"
            classes="btn-red size-l gtm-click-download-inquiry-section"
            :dlIcon="true"
            :isBlank="true"
            :href="links.download_inquiry_link"
          ></Btn>
        </div>
        <Tel></Tel>
      </slot>
    </SectionBox>
    <SectionBox title="会社概要" id="company" classes="company-section">
      <slot slot="body">
        <div class="flex center flex-wrap">
          <span>
            <img
              src="../image/logo_starfestival.svg"
              alt="STAR FESTIVAL"
              class="logo"
              width="364"
              height="34"
            />
          </span>
        </div>
        <CompanyData></CompanyData>
        <div class="flex center">
          <Btn
            text="詳しくはこちら"
            classes="btn-black-border size-l gtm-click-company-section"
            :blankIcon="true"
            href="https://stafes.co.jp/"
          ></Btn>
        </div>
      </slot>
    </SectionBox>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Btn from "./components/parts/Btn";
import CardList from "./components/parts/CardList";
import IntroductionCard from "./components/parts/IntroductionCard";
import Faq from "./components/parts/Faq";
import Tel from "./components/parts/Tel";
import AreaTable from "./components/parts/AreaTable";
import CommentCard from "./components/parts/CommentCard";
import Main from "./components/Main";
import SectionBox from "./components/SectionBox";
import CompanyData from "./components/CompanyData";

import FaqJson from "../data/questions.json";
import MediaJson from "../data/media.json";
import IntroductionJson from "../data/introduction.json";
import CommentJson from "../data/comment.json";
import LinksJson from "../data/links.json";

export default {
  name: "App",
  components: {
    Header,
    Main,
    SectionBox,
    Btn,
    Footer,
    Faq,
    CompanyData,
    Tel,
    CardList,
    IntroductionCard,
    AreaTable,
    CommentCard,
  },
  data() {
    return {
      faqs: FaqJson,
      medias: MediaJson,
      introductions: IntroductionJson,
      comments: CommentJson,
      links: LinksJson,
    };
  },
};
</script>

<style lang="scss">
@import "../scss/index.scss";
</style>
